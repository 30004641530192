import { createGlobalStyle } from 'styled-components';

import { ThemeProp } from './Themes';

export const GlobalStyles = createGlobalStyle<ThemeProp>`
  body {
    background: ${({ theme }: ThemeProp): string => theme.colors.white};
    color: ${({ theme }: ThemeProp): string => theme.colors.black};
    margin: 0;
    padding: 0
  }

  h4 {
    font-weight: 600;
    margin: 0;
    line-height: 24px;
    font-size: 16px;
  }

  .rjsf-form {
    width: 100%;
  }

  input:-webkit-autofill, input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
      background-color: transparent !important;
  }

  .nextui-popover-content-container {
    border-radius: ${({ theme }: ThemeProp): string => theme.borderRadius};
  }

  .nextui-popover-content {
    .nextui-table-container {
      box-shadow: none;
    }
  }

  .tooltip-wrapper, .tooltip-pixel-disabled {
    padding: 0.5rem;
    background: ${(props: ThemeProp) => props.theme.colors.black};
    border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
    max-width: 15rem;
    color: ${(props: ThemeProp) => props.theme.colors.c100};
    font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
    font-family: ${(props: ThemeProp) => props.theme.fonts.primary};

    .nextui-tooltip-arrow{
      background: ${(props: ThemeProp) => props.theme.colors.black};
    }

    // NOTE: These styles could be moved to a "mixin" constant and referenced.
    &--unconstrained {
      padding: 0.5rem;
      background: ${(props: ThemeProp) => props.theme.colors.black};
      border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
      color: ${(props: ThemeProp) => props.theme.colors.c100};
      font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
      font-family: ${(props: ThemeProp) => props.theme.fonts.primary};

      .nextui-tooltip-arrow{
        background: ${(props: ThemeProp) => props.theme.colors.black};
      }
    }
  }

  .tooltip-pixel-disabled {
    @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
      max-width: 12rem;
    }
  }

  .nextui-tooltip-button {
    width: unset;
  }
`;

export default GlobalStyles;
