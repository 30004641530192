import { Dropdown } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.xs};
  margin: 0 0 0.25rem 0.5rem;
  display: flex;
  align-items: center;
`;

export const StyledDropdown = styled(Dropdown).attrs((props) => ({
  ...props,
  isBordered: true,
  offset: 8,
}))``;

export const DropDownButton = styled(Dropdown.Button).attrs((props) => {
  const isDisabled = props.disabled;

  return {
    css: {
      color: props.theme.colors.black,
      height: 'calc($15 - 2px)',
      'border-radius': '$xxs',
      'border-color': props.theme.colors.c200,
      'padding-left': '$6',
      'padding-right': '$6',
      fontSize: '$input',
      fontWeight: '$normal',
      '&.nextui-dropdown-button': {
        justifyContent: 'space-between',
        background: isDisabled ? props.theme.colors.c050 : 'transparent',
        color: isDisabled ? props.theme.colors.c400 : props.theme.colors.black,
      },
      '&.nextui-dropdown-button[aria-haspopup="true"].nextui-dropdown-button[aria-expanded="true"]': {
        transform: 'unset',
      },
      '.nextui-button-text': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
      },
      '.nextui-button-icon-right': {
        stroke: props.theme.colors.black,
      },
      minWidth: 'unset',
      ...props.css,
    },
    bordered: true,
    ...props,
  };
})``;

interface DropDownMenuProps {
  width: number;
}

export const DropDownMenu = styled(Dropdown.Menu)<DropDownMenuProps>`
  max-width: unset;
  width: ${(props: DropDownMenuProps) => props.width}px;
  & .nextui-dropdown-item {
    height: unset;
    padding: 0 0.5rem;
  }
  .nextui-dropdown-item-content {
    overflow: hidden;
  }
`;

export const DropDownItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  height: 2rem;
`;

export const DropDownItemText = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropDownItemDescription = styled(DropDownItemText)`
  color: ${(props: ThemeProp) => props.theme.colors.c300};
  font-style: italic;
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props: ThemeProp) => props.theme.colors.c050};
`;
