import React from 'react';

const CalendarIcon = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.49812 2.99634V5.99759" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.5019 2.99634V5.99759" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.99597 9.49923H21.0035" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect
      x="2.99597"
      y="4.49707"
      width="18.0075"
      height="16.5069"
      rx="3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarIcon;
