import { Link as NextLink } from '@nextui-org/react';
import styled from 'styled-components';

const Link = styled(NextLink).attrs((props) => {
  return {
    css: {
      '&': {
        'font-size': '$sm',
        'line-height': '$xs',
        ...props.css?.['&'],
      },
      ...props.css,
    },
    ...props,
  };
})``;

export default Link;
