import { Amplify, Storage } from 'aws-amplify';

import { safelyJoinPaths } from './paths';

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      ...(process.env.REACT_APP_COGNITO_ENDPOINT && {
        endpoint: process.env.REACT_APP_COGNITO_ENDPOINT,
      }),
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_URL,
      oauth: {
        domain: process.env.REACT_APP_OAUTH_DOMAIN,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_URL,
        redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_URL,
        responseType: 'code',
      },
    },
    Storage: {
      bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      region: process.env.REACT_APP_COGNITO_REGION,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    },
  });
}

function generateName(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export async function uploadFile(file: File): Promise<string> {
  const fileName = generateName();
  try {
    const existingFile = await Storage.get(fileName, { download: true });
    if (existingFile) {
      // File already exist so trigger a new name
      return uploadFile(file);
    }
  } catch {
    // File doesn't exist
    const { key } = await Storage.put(fileName, file, {
      acl: 'public-read',
      contentType: file.type,
    });
    return getUploadPath(key);
  }
  return '';
}

const getUploadPath = (key: string): string => {
  return safelyJoinPaths(process.env.REACT_APP_S3_URI, key);
};
