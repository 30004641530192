import React from 'react';
import { useTranslation } from 'react-i18next';

import { inMobile, useWindowDimensions } from '../../hooks/useDimensions';
import { ButtonRole } from '../Button';
import {
  StyledBottomSheet,
  StyledButton,
  StyledFooter,
  StyledModal,
  StyledModalBody,
  StyledSecondaryButton,
} from './Modal.styles';
import ModalHeader from './ModalHeader';

export interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  title?: string;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
  reverseActionsOrder?: boolean;
  width?: string;
  loading?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  hideFooter?: boolean;
  centerTitle?: boolean;
  buttonRole?: ButtonRole;
  hideCloseButton?: boolean;
  hideCancelButton?: boolean;
  disabledSubmitButton?: boolean;
  disabledAutoMargin?: boolean;
}

const Modal = ({
  open,
  children,
  title,
  cancelButtonTitle,
  submitButtonTitle,
  reverseActionsOrder,
  width = '32.5rem',
  loading,
  onClose,
  onSubmit,
  onCancel,
  hideFooter = false,
  centerTitle = false,
  buttonRole = ButtonRole.Primary,
  hideCloseButton = false,
  hideCancelButton = false,
  disabledSubmitButton = false,
  disabledAutoMargin = false,
}: ModalProps) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowDimensions();

  const handleCancelClick = () => {
    onClose?.();
    onCancel?.();
  };

  const Header = () => (
    <ModalHeader centerTitle={centerTitle} title={title} hideCloseButton={hideCloseButton} onClose={onClose} />
  );

  const CancelButton = () => (
    <StyledSecondaryButton onClick={handleCancelClick}>{cancelButtonTitle || t('common.cancel')}</StyledSecondaryButton>
  );
  const SubmitButton = () => (
    <StyledButton disabled={disabledSubmitButton} $buttonRole={buttonRole} loading={loading} onClick={onSubmit}>
      {submitButtonTitle || t('common.ok')}
    </StyledButton>
  );
  const Footer = () => (
    <StyledFooter reverse={reverseActionsOrder}>
      {!hideCancelButton && <CancelButton />}
      <SubmitButton />
    </StyledFooter>
  );

  return (
    <>
      {inMobile(windowWidth) ? (
        <StyledBottomSheet open={open} onDismiss={onClose}>
          <Header />
          {children}
          {!hideFooter && <Footer />}
        </StyledBottomSheet>
      ) : (
        <StyledModal open={open} width={width} onClose={onClose}>
          <Header />
          <StyledModalBody autoMargin={!disabledAutoMargin}>{children}</StyledModalBody>
          {!hideFooter && <Footer />}
        </StyledModal>
      )}
    </>
  );
};

export default Modal;
