import LinearLoader from 'react-linear-loader';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const LinearLoading = styled(LinearLoader).attrs((props: ThemeProp) => ({
  ...props,
  height: '0.625rem',
  color: props.theme.colors.primary,
  backgroundColor: props.theme.colors.white,
}))``;
