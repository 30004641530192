import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledMotionDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  flex: 1;
`;
