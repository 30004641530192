import styled from 'styled-components';

import { ThemeProp } from '../../../theme/Themes';
import Button from '..';

const SecondaryButton = styled(Button).attrs((props) => ({
  ...props,
  bordered: true,
}))`
  &:disabled {
    background-color: ${(props: ThemeProp) => props.theme.colors.white};
    color: ${(props: ThemeProp) => props.theme.colors.primary};
    border-color: ${(props: ThemeProp) => props.theme.colors.primary};
  }
`;

export default SecondaryButton;
