import { Divider } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Items = styled.div`
  background-color: ${(props: ThemeProp) => props.theme.colors.white};
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.c050};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1rem;
  position: absolute;
  z-index: 400;
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  white-space: nowrap;
`;

export const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 2;
  gap: 1rem;
  height: 2rem;
  justify-content: space-between;
`;

export const ItemText = styled.span`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
`;

export const ItemDescription = styled(ItemText)`
  color: ${(props: ThemeProp) => props.theme.colors.c300};
  font-style: italic;
`;

export const Separator = styled(Divider)`
  background-color: ${(props: ThemeProp) => props.theme.colors.c050};
`;
