import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { safelyJoinPaths } from './utils/paths';

export const createAxiosClient = (config: AxiosRequestConfig): AxiosInstance => {
  const client = axios.create(config);

  return client;
};

export const axiosClient = createAxiosClient({
  baseURL: safelyJoinPaths(process.env.REACT_APP_GRAPHQL_URI, 'api'),
  headers: {
    'Content-Type': 'application/json',
  },
});
