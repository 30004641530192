import { Avatar as StyledAvatar } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

interface AvatarProps {
  borderRadius?: string;
  border?: string;
  fontSize?: string;
  fontColor?: string;
  backgroundColor?: string;
}

const defaultBorderRadius = '0.375rem';

const Avatar = styled(StyledAvatar)<AvatarProps>`
  border-radius: ${(props: AvatarProps) => props.borderRadius || defaultBorderRadius};
  .nextui-avatar-bg,
  .nextui-avatar-img {
    background-color: ${(props: ThemeProp & AvatarProps) => props.backgroundColor || props.theme.colors.primaryLight};
    border-radius: ${(props: AvatarProps) => props.borderRadius || defaultBorderRadius};
    border: ${(props: AvatarProps) => props.border || 'none'};
  }
  .nextui-avatar-text {
    color: ${(props: ThemeProp & AvatarProps) => props.fontColor || props.theme.colors.primary};
    font-size: ${(props: AvatarProps & ThemeProp) => props.fontSize || props.theme.fontSize.ml};
  }
`;

export default Avatar;
