import { Switch as NextSwitch } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
`;

export const StyledSwitch = styled(NextSwitch)`
  padding: 0;
`;

export const Title = styled.div`
  color: ${(props: ThemeProp) => props.theme.colors.black};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.regular};
  margin: auto auto auto 0.5rem;
`;
