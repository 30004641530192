import React from 'react';

const LinkIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.66699 10.6667C8.95329 11.0495 9.31856 11.3662 9.73803 11.5954C10.1575 11.8245 10.6213 11.9608 11.0981 11.9949C11.5749 12.0291 12.0534 11.9603 12.5012 11.7932C12.9491 11.6262 13.3557 11.3648 13.6937 11.0267L15.6937 9.02672C16.3009 8.39805 16.6368 7.55604 16.6292 6.68205C16.6216 5.80806 16.2711 4.97202 15.6531 4.354C15.035 3.73597 14.199 3.38541 13.325 3.37781C12.451 3.37022 11.609 3.7062 10.9803 4.31339L9.83366 5.45339"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3335 9.33332C11.0472 8.95057 10.6819 8.63387 10.2624 8.40469C9.84297 8.17552 9.37913 8.03924 8.90237 8.0051C8.4256 7.97095 7.94707 8.03974 7.49924 8.2068C7.0514 8.37386 6.64472 8.63527 6.3068 8.97332L4.3068 10.9733C3.69961 11.602 3.36363 12.444 3.37122 13.318C3.37881 14.192 3.72938 15.028 4.3474 15.646C4.96543 16.2641 5.80147 16.6146 6.67546 16.6222C7.54945 16.6298 8.39146 16.2938 9.02013 15.6867L10.1601 14.5467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkIcon;
