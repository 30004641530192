import Moment from 'moment';
import { DateRange, extendMoment } from 'moment-range';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import {
  Container,
  CustomPredefinedRangeButton,
  InputsContainer,
  PredefinedSelectionButton,
  PredefinedSelectionsColumnContainer,
  PredefinedSelectionsRowContainer,
  RangeSelectionContainer,
  StyledDateRangePicker,
  StyledDateTimeInput,
} from './DateRangePicker.styles';

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const moment = extendMoment(Moment as any);

interface PredefinedSelection {
  name: string;
  range: DateRange;
}

interface DateRangePickerProps {
  onChange?: (range: DateRange) => void;
  maxDaysCount?: number;
  selectedRange?: DateRange;
  maximumDate?: Date;
  setIsWrongRange?: (isWrong: boolean) => void;
}

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const DateRangePicker = ({
  onChange,
  maxDaysCount,
  selectedRange,
  maximumDate,
  setIsWrongRange,
  ...rest
}: DateRangePickerProps) => {
  const { t } = useTranslation();
  const [valueRange, setValueRange] = useState(selectedRange || moment.range(moment(), moment()));
  const [rangeCustom, setRangeCustom] = useState<DateRange>(selectedRange || undefined);
  const [valueFrom, setValueFrom] = useState<Date | Moment.Moment>(selectedRange?.start.startOf('day') || undefined);
  const [valueTo, setValueTo] = useState<Date | Moment.Moment>(selectedRange?.end?.startOf('day') || undefined);
  const [predefinedSelectiom, setPredefinedSelectiom] = useState<PredefinedSelection>(undefined);
  const [startDateSelected, setStartDateSelected] = useState<Date>(undefined);
  const [pendingDate, setPendingDate] = useState<Date>(undefined);

  const predefinedSelections: PredefinedSelection[] = [
    {
      name: t('components.dateRangePicker.last30days'),
      range: moment.range(moment().subtract(30, 'days'), moment()),
    },
    {
      name: t('components.dateRangePicker.thisMonth'),
      range: moment.range(moment().startOf('month'), moment().endOf('month')),
    },
    {
      name: t('components.dateRangePicker.lastMonth'),
      range: moment.range(
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
      ),
    },
  ];

  const normalizeRange = (range: DateRange) => {
    if (!maximumDate) return range;

    return range.intersect(moment.range(range.start, maximumDate));
  };

  const onPredefinedSelect = (selection: PredefinedSelection) => {
    handleSelect(normalizeRange(selection.range), false);
    setPredefinedSelectiom(selection);
    setStartDateSelected(undefined);
  };

  const isWrongRange = (range: DateRange) => {
    if (maxDaysCount) {
      const rangeDaysCount = moment.duration(range.end.diff(range.start)).asDays() + 1;
      return rangeDaysCount > maxDaysCount;
    }
    return false;
  };

  const onCustomRangeSelect = () => {
    if (!isWrongRange(rangeCustom)) {
      selectRange(rangeCustom);
      setPredefinedSelectiom(undefined);
    }
  };

  const CustomRangeButton = () => {
    const customRangeDaysCount = moment.duration(rangeCustom.end.diff(rangeCustom.start)).asDays() + 1;
    return (
      <CustomPredefinedRangeButton isSelected={!predefinedSelectiom} onClick={onCustomRangeSelect}>
        {t(
          customRangeDaysCount > 1
            ? 'components.dateRangePicker.daysPlural'
            : 'components.dateRangePicker.daysSingular',
          { count: Math.floor(customRangeDaysCount) },
        )}
      </CustomPredefinedRangeButton>
    );
  };

  const PredefinedSelections = () => (
    <>
      {predefinedSelections.map((selection: PredefinedSelection, index: number) => (
        <PredefinedSelectionButton
          isFirst={index === 0}
          isSelected={selection.name === predefinedSelectiom?.name}
          onClick={() => onPredefinedSelect(selection)}
        >
          {selection.name}
        </PredefinedSelectionButton>
      ))}
      {rangeCustom && <CustomRangeButton />}
    </>
  );

  const selectRange = (range: DateRange) => {
    if (isWrongRange(range)) {
      toast.error(t('components.dateRangePicker.maxDaysError', { count: maxDaysCount }));
    } else {
      setValueRange(range);
      setValueFrom(range.start);
      setValueTo(range.end);
      onChange?.(range);
    }
  };

  const handleSelectStart = (date: Date) => {
    if (!isTouchDevice()) {
      return;
    }

    setPendingDate(date);
    if (startDateSelected) {
      const range =
        startDateSelected < date ? moment.range(startDateSelected, date) : moment.range(date, startDateSelected);
      selectRange(range);
      setRangeCustom(range);
      setStartDateSelected(undefined);
      setPredefinedSelectiom(undefined);
    } else {
      setStartDateSelected(date);
      setValueFrom(date);
      setValueRange(moment.range(date, date));
    }
  };

  const handleSelect = (range: DateRange, isCustomRange = true) => {
    if (isTouchDevice() && isCustomRange) {
      return;
    }

    selectRange(range);
    setPredefinedSelectiom(undefined);
    if (isCustomRange && !isWrongRange(range)) {
      setRangeCustom(range);
    }
  };

  const onChangeInputValue = (range: DateRange) => {
    if (range.end >= range.start) {
      setValueRange(range);
    }
    setRangeCustom(range);
    setPredefinedSelectiom(undefined);
    onChange?.(range);
  };

  const onChangeStartValue = (date: Date) => {
    const range = moment.range(moment(date), valueRange.end);
    setValueFrom(date);
    onChangeInputValue(normalizeRange(range));
  };

  const onChangeEndValue = (date: Date) => {
    const range = moment.range(valueRange.start, moment(date));
    setValueTo(date);
    onChangeInputValue(normalizeRange(range));
  };

  React.useEffect(() => {
    // FIX: there was an issue on the mobile devices: touhed date cell is selecting after scroll/swipe action
    const handleDateTouchEnd = (e) => {
      e.stopPropagation();
    };

    window.addEventListener('touchmove', function () {
      const elements = document.getElementsByClassName('DateRangePicker__Date');
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('touchend', handleDateTouchEnd);
      }
    });

    window.addEventListener('touchend', function () {
      const elements = document.getElementsByClassName('DateRangePicker__Date');
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('touchend', handleDateTouchEnd);
      }
    });
  }, []);

  useEffect(() => {
    updateInvalidState(moment(valueFrom) > moment(valueTo) || valueFrom > maximumDate || valueTo > maximumDate);
  }, [valueFrom, valueTo]);

  const shouldSkipPendingState = () => {
    if (!isTouchDevice() || !pendingDate) return false;
    return !valueRange.contains(pendingDate);
  };

  const updateInvalidState = (isInvalid: boolean) => {
    setIsWrongRange?.(isInvalid);
  };

  const getInputError = (isFrom: boolean) => {
    const inputId = isFrom ? 'inputFrom' : 'inputTo';
    if (document.getElementById(inputId) !== document.activeElement) return undefined;

    if (moment(valueFrom) > moment(valueTo)) {
      return isFrom ? t('components.input.invalidFromDate') : t('components.input.invalidToDate');
    }

    if (!maximumDate) return undefined;

    const value = isFrom ? valueFrom : valueTo;
    if (value > maximumDate) {
      return t('components.input.maximumDateError');
    }

    return undefined;
  };

  return (
    <Container {...rest}>
      <RangeSelectionContainer>
        <InputsContainer>
          <StyledDateTimeInput
            id="inputFrom"
            label={t('components.dateRangePicker.from')}
            value={valueFrom}
            onChange={onChangeStartValue}
            error={getInputError(true)}
            setIsWrongDate={updateInvalidState}
          />
          <StyledDateTimeInput
            id="inputTo"
            label={t('components.dateRangePicker.to')}
            value={valueTo}
            onChange={onChangeEndValue}
            error={getInputError(false)}
            setIsWrongDate={updateInvalidState}
          />
        </InputsContainer>
        <PredefinedSelectionsRowContainer>
          <PredefinedSelections />
        </PredefinedSelectionsRowContainer>
        <StyledDateRangePicker
          numberOfCalendars={2}
          singleDateRange
          value={valueRange}
          onSelect={handleSelect}
          onSelectStart={handleSelectStart}
          skipPendingState={shouldSkipPendingState()}
          maximumDate={maximumDate}
        />
      </RangeSelectionContainer>
      <PredefinedSelectionsColumnContainer>
        <PredefinedSelections />
      </PredefinedSelectionsColumnContainer>
    </Container>
  );
};

export default DateRangePicker;
