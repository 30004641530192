import React from 'react';

const TrashIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.16663 5.83334H15.8333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15 5.83333V15C15 15.9208 14.2542 16.6667 13.3333 16.6667H6.66667C5.74583 16.6667 5 15.9208 5 15V5.83333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.5 3.12499H7.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.33329 9.16667V13.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.6667 9.16667V13.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default TrashIcon;
