import { Chevron, PopoverRow, ThemeProp } from '@trarc/pc-shared';
import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }: ThemeProp) => theme.colors.black};
  font-weight: ${({ theme }: ThemeProp) => theme.fontWeight.bold};
  font-size: ${({ theme }: ThemeProp) => theme.fontSize.xs};
`;

export const UserInfo = styled.div`
  display: flex;
  color: transparent;
  background-color: transparent;
  border: none;
  border-radius: 2px;

  &:focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }: ThemeProp) => theme.colors.white},
      0 0 0 4px ${(props: ThemeProp) => props.theme.colors.black};
  }
`;

export const UserName = styled.span`
  color: ${(props: ThemeProp) => props.theme.colors.black};
  padding: ${5 / 16}rem ${10 / 16}rem;
  padding-left: 0;
  margin: auto;
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20rem;

  &:hover {
    color: ${(props: ThemeProp) => props.theme.colors.primary};
    text-decoration-line: underline;
  }
`;

export const StyledChevronIcon = styled(Chevron)`
  flex-shrink: 0;
  stroke: ${(props: ThemeProp) => props.theme.colors.black};
  align-self: center;
  height: 1.5rem;
  width: 1.5rem;
`;

export const UserActionDropdownRow = styled(PopoverRow)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
