import React from 'react';

const CopyIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.2">
      <path
        d="M16.3996 7.6001H9.19961C8.31595 7.6001 7.59961 8.31644 7.59961 9.2001V16.4001C7.59961 17.2838 8.31595 18.0001 9.19961 18.0001H16.3996C17.2833 18.0001 17.9996 17.2838 17.9996 16.4001V9.2001C17.9996 8.31644 17.2833 7.6001 16.3996 7.6001Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.4 12.4H3.6C3.17565 12.4 2.76869 12.2314 2.46863 11.9314C2.16857 11.6313 2 11.2243 2 10.8V3.6C2 3.17565 2.16857 2.76869 2.46863 2.46863C2.76869 2.16857 3.17565 2 3.6 2H10.8C11.2243 2 11.6313 2.16857 11.9314 2.46863C12.2314 2.76869 12.4 3.17565 12.4 3.6V4.4"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CopyIcon;
