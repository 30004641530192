import { ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';

const backgroundProps = (props: ThemeProp) => {
  return `background: ${props.theme.gradients.shimmer};
      background-size: 1000px 100%;`;
};

interface ShimmerProps {
  width?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  borderRadius?: string;
}

export const ShimmerImage = styled(ShimmerThumbnail)<ShimmerProps>`
  && {
    display: block;
    &.shimmer {
      ${(props: ThemeProp) => backgroundProps(props)}
    }
    &.shimmer-thumbnail {
      min-width: unset;
      margin-bottom: ${(props: ShimmerProps) => props.marginBottom || '0px'} !important;
      width: ${(props: ShimmerProps) => props.width || '100%'};
      height: ${(props: ShimmerProps) => props.height || '100%'};
    }
    border-radius: ${(props: ShimmerProps) => props.borderRadius || '0.25rem'};
  }
`;

export const ShimmerText = styled(ShimmerTitle).attrs(() => {
  return { line: 1 };
})<ShimmerProps>`
  && {
    flex: 1;
    & .shimmer {
      ${(props: ThemeProp) => backgroundProps(props)}
    }
    &.shimmer-title {
      margin-bottom: ${(props: ShimmerProps) => props.marginBottom || '0px'} !important;
    }
    & .shimmer-title-line {
      margin-top: ${(props: ShimmerProps) => props.marginTop || '0'} !important;
      height: ${(props: ShimmerProps) => props.height || '1rem'};
      border-radius: ${(props: ShimmerProps) => props.borderRadius || '0.25rem'};
      &:first-child {
        width: ${(props: ShimmerProps) => props.width || '100%'} !important;
      }
    }
  }
`;
