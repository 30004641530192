import React, { ReactNode } from 'react';

import { pageTransitionDuration } from '../../../constants';
import { StyledMotionDiv } from './FadeInAnimation.styles';

interface FadeInAnimationProps {
  children: ReactNode;
  duration?: number;
  enableAnimation?: boolean;
}

const FadeInAnimation = ({
  children,
  duration = pageTransitionDuration,
  enableAnimation,
  ...rest
}: FadeInAnimationProps): any => {
  const variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
  };

  if (enableAnimation === false) {
    return <>{children}</>;
  }

  return (
    <StyledMotionDiv
      initial="initial"
      animate="enter"
      exit="initial"
      variants={variants}
      transition={{ duration }}
      {...rest}
    >
      {children}
    </StyledMotionDiv>
  );
};

export default FadeInAnimation;
