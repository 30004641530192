import { useEffect, useState } from 'react';

/**
 * Custom hook to handle storing items in `sessionStorage`. Once the browser is closed, the items will be cleared.
 * @param storageKey string value to identify the item in session storage
 * @param fallbackState if the item does not exist in storage or is null, then use this state
 * @returns state values for the stored `value` and setter
 */
const useSessionStorage = <T,>(storageKey: string, fallbackState: T) => {
  const [value, setValue] = useState<T>(() => {
    try {
      return (JSON.parse(sessionStorage.getItem(storageKey)) as T) ?? fallbackState;
    } catch (e) {
      return fallbackState;
    }
  });

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue] as const;
};

export default useSessionStorage;
