import React from 'react';
import { BottomSheetProps } from 'react-spring-bottom-sheet';

import { StyledBottomSheet } from './BottomSheet.styles';

interface StyledBottomSheetProps extends BottomSheetProps {
  zIndex?: number;
}
const BottomSheet = ({ zIndex, ...rest }: StyledBottomSheetProps) => {
  return <StyledBottomSheet zIndex={zIndex} {...rest} />;
};
export default BottomSheet;
