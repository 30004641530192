import { Divider } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import Link from '../Link';

export const TabsInnerContainer = styled.div`
  overflow-x: auto;
  position: relative;
  width: 100%;
  flex-wrap: nowrap;
  display: flex;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    height: 0px;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const Tab = styled(Link).attrs((props) => {
  const isActive = props.status === 'active';
  const color = isActive ? '$secondary' : '$grayScaleText';
  return {
    ...props,
    css: {
      ...props.css,
      '&': {
        ...props.css?.['&'],
        color,
        borderBottom: isActive ? '2px solid $secondary' : '2px solid transparent',
        marginRight: '1.5rem',
        zIndex: '2',
        whiteSpace: 'nowrap',
        stroke: color,
      },
    },
  };
})`
  padding-bottom: 1rem;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding-bottom: 0.5rem;
  }
  svg {
    margin: auto 0.5rem auto 0;
  }
`;

export const Title = styled.div`
  margin: auto 0;
`;

export const ScrollHint = styled.div`
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 1.5rem;
  height: 100%;
  z-index: 4;
  background: linear-gradient(270deg, #f9f9fe 26.37%, rgba(249, 249, 254, 0) 100%);

  @media (min-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: -1px;
`;

export const PageContainer = styled.div`
  width: 100%;
  padding: 2rem 5rem;

  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 1rem;
  }
`;
