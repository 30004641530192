import { Modal } from '@nextui-org/react';
import styled from 'styled-components';

import { ThemeProp } from '../../theme/Themes';
import { Button, SecondaryButton } from '../';
import BottomSheet from '../BottomSheet';
import { ButtonRole } from '../Button';

export const StyledModal = styled(Modal)`
  display: flex;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: none;
  }
  &.nextui-modal {
    border-radius: 0.375rem;
  }
`;

export const StyledModalBody = styled(Modal.Body)`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  color: ${(props: ThemeProp) => props.theme.colors.c400};
  padding-bottom: 2rem;
`;

export const StyledBottomSheet = styled(BottomSheet)`
  display: none;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

interface ButtonProps {
  $buttonRole: ButtonRole;
}

export const StyledButton = styled(Button)<ButtonProps>`
  background-color: ${(props) => props.$buttonRole.backgroundColor(props.theme)};
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex: 1;
  }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    flex: 1;
  }
`;

interface FooterProps {
  reverse?: boolean;
}

export const StyledFooter = styled.div<FooterProps>`
  display: flex;
  flex-direction: ${(props: FooterProps) => (props.reverse ? 'row-reverse' : 'row')};
  width: 100%;
  gap: 1rem;
  justify-content: ${(props: FooterProps) => (props.reverse ? 'flex-start' : 'flex-end')};
  padding: 0 1.5rem 1.5rem 1.5rem;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.mobile}) {
    padding: 2rem 0 2rem 0;
  }
`;
