import React, { ChangeEvent } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete';

import Input from '../Input';
import { Item, ItemContainer, ItemDescription, Items, ItemText, Separator } from './PlacesAutocomplete.styles';

export interface AutocompletePlace {
  address: string;
  city: string;
  country: string;
  latitude?: number;
  longitude?: number;
  state: string;
  zip: string;
}

interface PlacesAutocompleteProps {
  label: string;
  placeholder?: string;
  helpText?: string;
  onSelect: (place: AutocompletePlace) => void;
  defaultValue?: string;
}

export enum PlaceAddressComponent {
  ZIP_CODE = 'postal_code',
  COUNTRY = 'country',
  STATE = 'administrative_area_level_1',
  CITY = 'locality',
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export function getAddressComponent(
  address_components: AddressComponent[],
  key: string,
  attribute: 'long_name' | 'short_name' = 'long_name',
) {
  let value = '';
  const addressComponents = address_components.filter((addressComponent) =>
    addressComponent.types.some((typesItem) => typesItem === key),
  );
  if (addressComponents !== null && addressComponents.length > 0) value = addressComponents[0][attribute];
  return `${value}`;
}

const PlacesAutocomplete = (props: PlacesAutocompleteProps) => {
  const { label, onSelect, placeholder, helpText, defaultValue } = props;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      componentRestrictions: { country: 'USA' },
    },
    defaultValue,
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = async (place: any) => {
    const {
      place_id,
      description,
      structured_formatting: { main_text },
    } = place;

    if (typeof description === 'string') {
      setValue(description, false);
    }
    clearSuggestions();

    // Get latitude and longitude via utility functions
    const results = await getGeocode({ address: description });
    const { lat, lng } = getLatLng(results?.[0]);

    const detailsResult = await getDetails({ placeId: place_id });
    const addressComponents = detailsResult.address_components as AddressComponent[];
    const country = getAddressComponent(addressComponents, PlaceAddressComponent.COUNTRY, 'short_name');
    const city = getAddressComponent(addressComponents, PlaceAddressComponent.CITY);
    const zip = getAddressComponent(addressComponents, PlaceAddressComponent.ZIP_CODE);
    const state = getAddressComponent(addressComponents, PlaceAddressComponent.STATE, 'short_name');

    onSelect({
      address: main_text,
      city,
      country,
      latitude: lat,
      longitude: lng,
      state,
      zip,
    });
  };

  const renderSuggestions = () => {
    return (
      <Items>
        {data.map((suggestion, index) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <Item key={place_id} onClick={() => handleSelect(suggestion)}>
              <ItemContainer>
                <ItemText>{main_text}</ItemText>
                {secondary_text && <ItemDescription>{secondary_text}</ItemDescription>}
              </ItemContainer>
              {index !== data.length - 1 && <Separator />}
            </Item>
          );
        })}
      </Items>
    );
  };

  return (
    <div ref={ref}>
      <Input
        required
        fullWidth
        helpText={helpText}
        label={label}
        placeholder={placeholder}
        value={value}
        disabled={!ready}
        onChange={handleInput}
      />
      {status === 'OK' && renderSuggestions()}
    </div>
  );
};

export default PlacesAutocomplete;
