import React from 'react';

const UserProfileIcon = (props: any) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.473 9.15182C12.2866 9.96541 12.2866 11.2845 11.473 12.0981C10.6594 12.9117 9.34029 12.9117 8.5267 12.0981C7.71311 11.2845 7.71311 9.96541 8.5267 9.15182C9.34029 8.33823 10.6594 8.33823 11.473 9.15182"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0448 4.15185C16.8583 4.96544 16.8583 6.28453 16.0448 7.09813C15.2312 7.91172 13.9121 7.91172 13.0985 7.09813C12.2849 6.28453 12.2849 4.96544 13.0985 4.15185C13.9121 3.33826 15.2312 3.33826 16.0448 4.15185"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 10.2083C16.2775 10.2083 17.5 10.6525 18.3333 11.3192"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9012 4.15185C7.71479 4.96544 7.71479 6.28453 6.9012 7.09813C6.0876 7.91172 4.76851 7.91172 3.95492 7.09813C3.14133 6.28453 3.14133 4.96544 3.95492 4.15185C4.76851 3.33826 6.0876 3.33826 6.9012 4.15185"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.6665 11.3192C2.49984 10.6525 3.72234 10.2083 4.99984 10.2083"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8255 16.4583C12.8547 15.7075 11.4597 15.2083 9.99966 15.2083C8.53966 15.2083 7.14466 15.7075 6.17383 16.4583"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserProfileIcon;
