import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppContext } from '../../hooks/AppContext';

export default function AuthenticatedRoute() {
  const { pathname, search } = useLocation();
  const { isAuthenticated, wasLoggedIn } = useAppContext();

  const redirect = wasLoggedIn ? '' : `?redirect=${pathname}${search}`;

  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/login${redirect}`, { replace: true });
    }
  }, [isAuthenticated]);
  return <Outlet />;
}
