import { Text as NextText } from '@nextui-org/react';
import styled from 'styled-components';

const Text = styled(NextText).attrs((props) => ({
  ...props,
  css: {
    margin: 0,
  },
}))``;

export default Text;
