import React, { createContext, FC, ReactNode } from 'react';

import { useMeQuery, User } from '../types/api.graphql';

type Nullable<T> = T | null;

interface SessionContextProps {
  me: Nullable<User>;
  hasOrgWithPaidSubscription: boolean;
}

interface SessionProviderProps {
  children: ReactNode;
}

const SessionContext = createContext<SessionContextProps>({ me: null, hasOrgWithPaidSubscription: false });

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const { data } = useMeQuery();

  const hasOrgWithPaidSubscription = data?.me?.organizations?.some((org) => org?.paidSubscription);

  return (
    <SessionContext.Provider
      value={{
        me: data?.me || null,
        hasOrgWithPaidSubscription,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext: () => SessionContextProps = () => {
  return React.useContext(SessionContext);
};
