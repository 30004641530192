import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import es from 'javascript-time-ago/locale/es.json';
import { initReactI18next } from 'react-i18next';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(es);

/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const i18nFormat = (value: any, format?: string | undefined, lng?: string | undefined, _options?: any) => {
  switch (format) {
    case 'uppercase':
      return value.toUpperCase();
    case 'price':
      return Intl.NumberFormat(lng, {
        style: 'currency',
        currency: value.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value.value as number);
    case 'distance':
      return (
        Intl.NumberFormat(lng, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value.value as number) +
        (value.unit as string)
      );
    case 'number':
      return Intl.NumberFormat(lng).format(value as number);
    case 'ordinal':
      if (!lng?.includes('en')) return `${value}`;
      const pr = new Intl.PluralRules(lng, { type: 'ordinal' });
      const ordinals = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        many: 'th',
        zero: 'th',
        other: 'th',
      };
      return `${value}${ordinals[pr.select(value)]}`;
    case 'date':
      return Intl.DateTimeFormat(lng, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(value as Date);
    case 'dateShort':
      return Intl.DateTimeFormat(lng, {
        month: 'short',
        day: 'numeric',
      }).format(value as Date);
    case 'dateGraph':
      const dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(value as Date);
      return dateFormatter.replaceAll('/', '-');
    case 'dateBars':
      return Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(value as Date);
  }
};

const resources = {
  en: require(`./en.json`),
  es: require(`./es.json`),
};

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    resources,
    interpolation: {
      format: i18nFormat,
    },
  });

export default i18n;
