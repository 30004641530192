import styled from 'styled-components';

import { CalendarIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';
import Input from '../Input';

export const StyledInput = styled(Input)`
  .nextui-input-content {
    width: 1.25rem;
  }
  .nextui-input {
    margin: 0;
  }
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.c400};
`;
