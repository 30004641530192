import {
  Collapse,
  CollapseDropdownContainer,
  RadioGroup,
  RadioGroupProps,
  RadioInputLabel,
  StyledTextArea,
  TextArea,
  TextAreaContent,
  TextAreaProps,
  ThemeProp,
} from '@trarc/pc-shared';
import styled from 'styled-components';

export const QuestionnaireContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const QuestionnaireSection = styled(Collapse)`
  width: 100%;
  ${CollapseDropdownContainer} {
    padding: 0 1rem;
  }
`;

export const QuestionContainer = styled.div`
  margin: 1rem 0rem;
`;

export const QuestionEditContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
`;

export const Notes = styled(TextArea)`
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${TextAreaContent} {
    ${(props: ThemeProp & TextAreaProps) => (props.disabled ? `background-color: ${props.theme.colors.c050}` : '')};
  }
  ${StyledTextArea} {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${(props: ThemeProp & TextAreaProps) => (props.disabled ? `background-color: ${props.theme.colors.c050}` : '')};
  }
`;

export const Answers = styled(RadioGroup)`
  margin: 1rem;
  & ${RadioInputLabel} {
    cursor: ${(props: RadioGroupProps<unknown, string>) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
  flex-shrink: 0;
` as typeof RadioGroup;
