import styled from 'styled-components';

import { ThemeProp } from '../../../../theme/Themes';
import { IconButton } from '../../..';

export const Container = styled.div`
  display: none;
  position: relative;
  @media (max-width: ${(props: ThemeProp) => props.theme.breakpoints.tablet}) {
    display: flex;
  }
`;

export const Button = styled(IconButton).attrs(() => {
  return { bordered: true };
})`
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  border-color: ${(props: ThemeProp) => props.theme.colors.c200};
  background-color: ${(props: ThemeProp) => props.theme.colors.white};
  stroke: ${(props: ThemeProp) => props.theme.colors.c400};
  :focus {
    box-shadow: unset;
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -2px;
  top: -2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${(props: ThemeProp) => props.theme.colors.white};
  background-color: ${(props: ThemeProp) => props.theme.colors.black};
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.xxs};
`;
