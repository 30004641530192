import { CheckIcon, CloseIcon, ThemeProp } from '@trarc/pc-shared';
import styled from 'styled-components';

import { PenIcon, PlusIcon, TrashIcon } from '../../assets';
import { IconProps } from './types';

export const StyledCheckIcon = styled(CheckIcon)<IconProps>`
  stroke: ${({ theme, color = 'green' }: ThemeProp & IconProps) => theme.colors[color]};
`;

export const StyledCloseIcon = styled(CloseIcon)<IconProps>`
  stroke: ${({ theme, color = 'red' }: ThemeProp & IconProps) => theme.colors[color]};
`;

export const StyledPenIcon = styled(PenIcon)<IconProps>`
  stroke: ${({ theme, color = 'black' }: ThemeProp & IconProps) => theme.colors[color]};
`;

export const StyledTrashIcon = styled(TrashIcon)<IconProps>`
  stroke: ${({ theme, color = 'red' }: ThemeProp & IconProps) => theme.colors[color]};
`;

export const StyledPlusIcon = styled(PlusIcon)`
  stroke: ${({ theme, color = 'white' }: ThemeProp & IconProps) => theme.colors[color]};
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  margin: 0.375rem 0rem 0.375rem 0.375rem;
`;
