import { useTranslation } from 'react-i18next';

import { Question, QuestionOption } from '../types';
import { Answers, Notes, QuestionAnswerContainer } from './QuestionnaireItem.styles';

interface QuestionnaireItemProps {
  question: Question;
  onNotesChange?: (notes: string) => void;
  onAnswerChange?: (answer: QuestionOption) => void;
  readOnly?: boolean;
  enableAdditionalNotes?: boolean;
  className?: string;
}

export const QuestionnaireItem = ({
  question: { description, options, code, answer },
  enableAdditionalNotes,
  readOnly,
  className,
  onNotesChange,
  onAnswerChange,
}: QuestionnaireItemProps) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <p>{description}</p>
      <QuestionAnswerContainer>
        <Answers
          inputs={options}
          selectedKey={answer?.code}
          keySelector={({ code }) => code}
          textSelector={({ title }) => title}
          disabled={readOnly}
          onSelectionChange={(selection) => onAnswerChange(options.find(({ code }) => code === selection))}
          groupName={code}
        />
        {enableAdditionalNotes && (
          <Notes
            label={t('common.additionalNotes')}
            value={answer?.otherText || ''}
            disabled={readOnly}
            maxRows={4}
            onChange={(e) => onNotesChange(e.target.value)}
          />
        )}
      </QuestionAnswerContainer>
    </div>
  );
};
